<template>
  <v-card v-if="books && books.length > 0">
    <v-toolbar color="" class="elevation-0">
      <v-spacer></v-spacer>
      <v-toolbar-title v-if="title">{{ title }}</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <div class="books-similar pa-4" :class="$vuetify.breakpoint.xsOnly ? 'mobile' : ''">
      <book-small-card v-for="(book, index) in books" 
      :key="book.id" 
      :item="book" 
      :index="index" 
      :library="false" 
      :admin="false">
      </book-small-card>

    </div>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";

import BookSmallCard from "./BookSmallCard.vue";
export default {
  components: { BookSmallCard},
  data: () => ({
    
  }),
  methods: {
   
  },
  props: ["title", "books"],
  computed: {
    ...mapGetters(["currentUser", "loadPage"]),
   
  }
};
</script>
