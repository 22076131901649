<template>
  <div class="book-small-card">
    <router-link
      :to="{ name: 'BookShow', params: { slug: item.slug } }"
      class="book-image"
    >
      <img v-if="item.image_small" :src="item.image_small" />
      <div
      v-if="item.loyalty > 0"
        class="book-small-vip"
      >
      <div class="text">vip</div>
      <div class="angle"></div>
      </div>
      <div
        v-if="item.discount_size && item.discount_size > 0"
        class="book-small-discount"
      >
        -{{item.discount_size}}%
      </div>
    </router-link>
    <div class="book-title">
      <router-link :to="{ name: 'BookShow', params: { slug: item.slug } }">{{
        item.title
      }}</router-link>
    </div>
    <div class="book-user-name">
      <router-link
        :to="{ name: 'UserShow', params: { userName: item.user.name } }"
        >{{ item.user.public_name }}</router-link
      >
      <span
        v-if="item.coauthors && item.coauthors.length > 0"
        class="book-coauthors"
      >
        (<router-link
          :to="{
            name: 'UserShow',
            params: { userName: item.coauthors[0].name },
          }"
          >{{ item.coauthors[0].public_name }}</router-link
        >)
      </span>
    </div>
    <!-- <book-info
        :info="{count_likes:item.count_likes, count_libraries:item.count_libraries, count_comments:item.count_comments}"
      ></book-info> -->
    <!-- <div class="book-price" v-if="item.price">Цена: {{ item.price }} руб.</div> -->
  </div>
</template>
<script>
// import BookInfo from "./BookInfo.vue";
export default {
  // components: { BookInfo },
  props: ["item", "index", "library", "admin"],
  data() {
    return {};
  },
  methods: {
    deleteLibraryBook() {
      this.$emit("remove-library-book-modal", [this.item.id, this.index]);
    },
    deleteBook() {
      this.$emit("remove-book-modal", [this.item.id, this.index]);
    },
  },
};
</script>
