<template >
  <div
    :class="{ 'theme--dark': darkTheme }"
    :style="{ color: textColorTheme, background: colorTheme }"
  >
    <fullscreen
      class="reader-wrapper no-copy"
      :class="{ 'theme--dark': darkTheme }"
      :style="{ color: textColorTheme, background: colorTheme }"
      ref="fullscreen"
    >
      <v-navigation-drawer
        v-model="drawerRight"
        app
        right
        temporary
        clipped
        fixed
        :dark="darkTheme"
        :color="colorTheme"
      >
        <v-list dense>
          <v-list-item @click.stop="drawerRight = !drawerRight">
            <!-- <v-list-item-content>
              <v-list-item-title>Закрыть</v-list-item-title>
            </v-list-item-content>-->
            <v-spacer></v-spacer>
            <v-list-item-action>
              <v-icon>$vuetify.icons.arrow_right</v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="text-center">Яркость</v-list-item-title>
              <v-slider
                v-model="brightness"
                min="0"
                max="4"
                prepend-icon="$vuetify.icons.brightness_6"
              >
                <template v-slot:append>{{ brightness + 1 }}</template>
              </v-slider>
              <!-- <v-switch inset v-model="brightness" class="readerSwitch" label="Темная тема"></v-switch> -->
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="text-center">Размер</v-list-item-title>
              <v-slider
                v-model="readerfontSize"
                min="14"
                max="40"
                prepend-icon="$vuetify.icons.format_size"
              >
                <template v-slot:append>{{ readerfontSize }}</template>
              </v-slider>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="$vuetify.breakpoint.width >= 700">
            <v-list-item-content>
              <v-list-item-title class="text-center">Поля</v-list-item-title>
              <v-slider
                v-model="paddingSize"
                min="0"
                :max="readerWidthMax"
                prepend-icon="$vuetify.icons.text_box_outline"
              >
                <template v-slot:append>{{ paddingSize }}</template>
              </v-slider>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="text-center"
                >Высота строк</v-list-item-title
              >
              <v-slider
                v-model="rowHeight"
                min="105"
                max="165"
                prepend-icon="$vuetify.icons.format_line_spacing"
              >
                <template v-slot:append>{{ rowHeight / 100 }}</template>
              </v-slider>
            </v-list-item-content>
          </v-list-item>

          <!-- readerfontSize: 20,//11-40
    paddingSize:50,//16-284
    rowHeight:1.65,//1.05-1.65em
          fontBookNum:0,-->
        </v-list>
      </v-navigation-drawer>

      <v-navigation-drawer
        v-model="showLeftSidebar"
        app
        clipped
        left
        fixed
        temporary
        :dark="darkTheme"
        :color="colorTheme"
      >
        <v-list dense>
          <v-list-item @click.stop="showLeftSidebar = !showLeftSidebar">
            <v-list-item-action>
              <v-icon>$vuetify.icons.arrow_left</v-icon>
            </v-list-item-action>
          </v-list-item>
          <div v-if="book" class="reader-book-info">
            <div class="reader-book-info-title mb-4">{{ book.title }}</div>
            <div class="reader-book-info-top">
              <div
                v-if="!isMobile && book.image_small"
                class="reader-book-info-image"
              >
                <img :src="book.image_small" />
              </div>
              <div class="reader-book-info-right">
                <div class="book-user-name">{{ book.user.public_name }}</div>
                <!-- <book-categories v-if="book.categories" :categories="book.categories"></book-categories> -->

                <book-statistics :book="book"></book-statistics>
              </div>
            </div>
            <book-info
              :info="{
                count_likes: book.count_likes,
                count_libraries: book.count_libraries,
                count_comments: book.count_comments,
              }"
            ></book-info>
            <v-btn
              v-if="book.user.donat"
              @click="getDonatLink"
              color="primary"
              class="mt-2"
              dark
              >Поддержать</v-btn
            >
          </div>
          <v-subheader class="px-4 header-table-of-contents"
            >СОДЕРЖАНИЕ</v-subheader
          >
          <v-list-item-group v-if="chapters && chapters.length > 0">
            <v-list-item
              v-for="item in chapters"
              :key="item.id"
              :to="{
                name: 'ReaderShowFull',
                params: { bookSlug: book.slug, id: item.id },
              }"
              text
              tile
            >
              <v-list-item-content>
                <v-list-item-title class="book-content-chapters-title">
                  {{ item.title }}
                  <span
                    v-if="admin"
                    :class="+item.status ? 'active' : ''"
                    class="chapter-status-dot"
                  ></span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>

      <v-app-bar
        app
        clipped-left
        dense
        hide-on-scroll
        :dark="darkTheme"
        :color="colorTheme"
      >
        <v-app-bar-nav-icon
          v-if="isMobile"
          @click.stop="showLeftSidebar = !showLeftSidebar"
        ></v-app-bar-nav-icon>

        <v-btn
          v-if="!isMobile"
          class="reader-icon"
          outlined
          @click.stop="showLeftSidebar = !showLeftSidebar"
        >
          <v-icon left>$vuetify.icons.format_list_bulleted</v-icon>Оглавление
        </v-btn>

        <v-btn
          v-if="book && !isMobile"
          :to="{ name: 'BookShow', params: { slug: book.slug } }"
          class="reader-icon"
          outlined
        >
          <v-icon left>$vuetify.icons.book_open_variant</v-icon>Вернуться в
          книгу
        </v-btn>
        <v-btn
          v-if="book && !isMobile"
          @click="play"
          class="reader-icon px-0"
          outlined
        >
          <v-icon v-if="!played">$vuetify.icons.play_circle</v-icon>
          <v-icon v-else>$vuetify.icons.pause_circle</v-icon>
        </v-btn>

        <!-- <v-btn
          v-if="!isMobile"
          class="reader-icon"
          outlined
          @click="speak"
        >
          <v-icon left>$vuetify.icons.volume_source</v-icon>Озвучить книгу
        </v-btn> -->
        <v-btn
          v-if="book && isMobile"
          :to="{ name: 'BookShow', params: { slug: book.slug } }"
          class="reader-icon"
          icon
        >
          <v-icon>$vuetify.icons.home</v-icon>
        </v-btn>
        <v-btn v-if="book && isMobile" @click="play" class="reader-icon" icon>
          <v-icon v-if="!played">$vuetify.icons.play_circle</v-icon>
          <v-icon v-else>$vuetify.icons.pause_circle</v-icon>
        </v-btn>
        <v-btn
          v-if="admin && chapter"
          :to="{ name: 'CabinetChapterEdit', params: { id: chapter.id } }"
          class="reader-icon px-0"
          :outlined="!isMobile"
          :icon="isMobile"
        >
          <v-icon>$vuetify.icons.square_edit_outline</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          @click="toggleFullScreen"
          class="reader-icon px-0"
          :outlined="!isMobile"
          :icon="isMobile"
        >
          <v-icon>$vuetify.icons.fullscreen</v-icon>
        </v-btn>

        <v-btn
          @click.stop="drawerRight = !drawerRight"
          class="reader-icon px-0"
          :outlined="!isMobile"
          :icon="isMobile"
        >
          <v-icon>$vuetify.icons.cog</v-icon>
        </v-btn>
      </v-app-bar>
      <reader-speech
        ref="Player"
        v-if="book && chapter"
        :text="body_speech"
        :chapterId="chapter.id"
        :bookId="book.id"
        :colorTheme="colorTheme"
        :darkTheme="darkTheme"
      ></reader-speech>
      <no-content
        v-if="!book"
        route-name="Home"
        title
        button-name="Вернуться на сайт"
      ></no-content>
      <reader-nav
        v-if="showContent"
        :page="page"
        :pages="pages"
        @go-page="goPage"
      ></reader-nav>
      <reader-chapters-nav
        v-if="showContent"
        :chapters="chapters"
        :pos="chapterPos"
        @go-chapter="goChapter"
      ></reader-chapters-nav>

      <div
        v-if="chapter"
        class="reader-block"
        :style="{
          fontSize: readerfontSize + 'px',
          lineHeight: rowHeight / 100 + 'em',
          width: readerWidth,
          color: textColorTheme,
          background: colorTheme,
        }"
      >
        <div class="reader-title">
          {{ chapter.title }}
          <span
            v-if="admin"
            :class="+chapter.status ? 'success--text' : ''"
            class="chapter-status"
            >({{ +chapter.status ? "опубликована" : "черновик" }})</span
          >
        </div>
        <div
          v-if="showContent"
          class="reader-body"
          v-html="currentPageContent"
        ></div>
        <!-- <div
          class="to-be-continium text-center"
          v-if="showContent && chapterPos == chapters.length && !+book.finish && page == pages"
        >{{ +book.fake_fragment ? 'Конец ознакомительного фрагмента' : 'Продолжение следует...'}}</div>
        <div
          class="to-be-continium text-center"
          v-if="showContent && chapterPos == chapters.length && +book.finish  && page == pages"
        >{{ +book.fake_fragment ? 'Конец ознакомительного фрагмента' : 'Конец'}}</div> -->
        <div
          class="to-be-continium text-center"
          v-if="showContent && chapterPos == chapters.length && page == pages"
        >
          {{ +book.finish ? "Конец" : "Продолжение следует..." }}
        </div>
        <div class="not-content" v-if="currentUser && !showContent && show_buy">
          <div class="title">Книга находится в платном доступе</div>
          <v-btn @click="getBuyLink" :loading="buyLoading"
            >{{ +book.finish ? "Купить" : "Подписка" }}
            {{ book.price }} руб.</v-btn
          >
        </div>
        <no-content
          v-if="!showContent && show_buy && !currentUser"
          route-name="Login"
          :route-query="{ return_url: $route.fullPath }"
          title="Книга находится в платном доступе"
          button-name="Авторизоваться"
        ></no-content>
        <!-- <no-content
          v-if="!showContent && adult_block"
          :route-name="!currentUser ? 'Login' : ''"
          :route-query="{ return_url: $route.fullPath }"
          title="Книга содержит контент для взрослых. Вы не можете читать данную книгу, так как при регистрации на сайте не указали, что вам есть 18."
          :button-name="!currentUser ? 'Авторизоваться' : ''"
        ></no-content> -->
        <div class="not-content" v-if="!loadPage && !showContent && adultBlock">
          <!-- <div v-if="currentUser" class="title">
            Книга содержит контент для взрослых. Вы не можете читать данную
            книгу, так как при регистрации на сайте не указали, что вам есть 18.
          </div>
          <div v-else class="title text-center">
            Книга содержит контент для взрослых.<br> Вам уже есть 18? 
          <div class="adult_buttons mt-4"> -->

          <div class="title text-center">
            Книга содержит контент для взрослых.<br />
            Вам уже есть 18?
            <div class="adult_buttons mt-4">
              <v-btn @click="disableAdultBlock" class="mr-2" color="success"
                >Да</v-btn
              >
              <v-btn
                v-if="book && !isMobile"
                :to="{ name: 'BookShow', params: { slug: book.slug } }"
                class="ml-2"
                >Нет</v-btn
              >
            </div>
            <!-- <v-btn
            v-if="!currentUser"
            :to="{ name: 'Login', query: { return_url: $route.fullPath } }"
            >Авторизоваться</v-btn
          >
          <v-btn
            v-if="!currentUser"
            class="mt-4"
            :to="{ name: 'Register', query: { return_url: $route.fullPath } }"
            >Регистрация</v-btn
          > -->
          </div>
        </div>
        <no-content
          v-if="book && !showContent && showAddComment"
          route-name="BookShow"
          :route-params="{ slug: book.slug }"
          title="Пожалуйста оставьте отзыв о книге, это поможет загружать сайт интересными книгами, а вам читать дальше бесплатно."
          button-name="Оставить комментарий"
        ></no-content>
      </div>

      <reader-nav
        v-if="showContent"
        :page="page"
        :pages="pages"
        @go-page="goPage"
      ></reader-nav>
      <reader-chapters-nav
        v-if="showContent"
        :chapters="chapters"
        :pos="chapterPos"
        @go-chapter="goChapter"
      ></reader-chapters-nav>
      <v-container>
        <book-similar
          v-if="similar && similar.length > 0"
          :books="similar"
          title="Рекомендуем"
        ></book-similar>

        <v-dialog v-model="showInLibrary" class="text-center" max-width="290">
          <v-card>
            <v-card-title class="headline justify-center"
              >Понравилась книга?</v-card-title
            >

            <v-card-text class="text-center"
              >Отложите ее в библиотеку чтобы не потерять.</v-card-text
            >

            <v-card-actions class="justify-space-around">
              <v-btn
                color="green darken-1"
                :loading="libraryLoading"
                text
                @click="addLibrary"
                >Добавить</v-btn
              >

              <v-btn color="red darken-1" text @click="closeShowInLibrary"
                >Закрыть</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>
      <v-container v-if="loadPage && !chapter">
        <v-row align="center">
          <no-content
            route-name="AccountLibrary"
            title="В книге еще нет глав"
            button-name="Вернуться в библиотеку"
          ></no-content>
        </v-row>
      </v-container>
    </fullscreen>
    <banner index="0"></banner>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import BookInfo from "./BookInfo.vue";
import ReaderNav from "./ReaderNav.vue";
import ReaderChaptersNav from "./ReaderChaptersNav.vue";
import BookStatistics from "./BookStatistics.vue";
import BookSimilar from "./BookSimilar.vue";
import Banner from "../Banner.vue";
// import BookCategories from "./BookCategories.vue";
import Fullscreen from "vue-fullscreen/src/component.vue";
import ReaderSpeech from "./ReaderSpeech.vue";
export default {
  components: {
    BookInfo,
    BookStatistics,
    Fullscreen,
    ReaderNav,
    ReaderChaptersNav,
    BookSimilar,
    Banner,
    ReaderSpeech,
  },
  data: () => ({
    order: null, //Нужно для проверки платежа
    showLeftSidebar: null,
    drawerRight: null,
    // bookId: null, //Ид книги
    buyLoading: false,
    bookSlug: null, //Ид книги
    chapterId: null, //Ид Части
    fullscreen: false,
    brightness: 4,
    readerfontSize: 20, //11-40
    paddingSize: 25, //16-284 там ширина контента должна быть от 700 до 100%
    rowHeight: 165, //1.05-1.65em
    fontBookNum: 0,
    themes: [
      {
        dark: true,
        color: null,
        textColor: "#2f8d3f",
      },
      {
        dark: true,
        color: null,
        textColor: "#fff",
      },
      {
        dark: false,
        color: "#f5efdd",
        textColor: "#6d4231",
      },
      {
        dark: false,
        color: "#faf8f2",
        textColor: "#444444",
      },
      {
        dark: false,
        color: "#fff",
        textColor: null,
      },
    ],
    // chapterByPages: [],
    page: 1,
    // readerColor:'#fff',
    // readerDark:false,
    showInLibrary: false,
    libraryLoading: false,
    library: false,
    adultBlock: false,
    played: false,
  }),
  metaInfo() {
    if (this.book) {
      return {
        title: `${this.book.title}: все книги читать онлайн бесплатно`,
        meta: [
          {
            name: "description",
            content: this.metaDescr,
          },
          {
            property: "og:title",
            content: this.book.title + ": все книги читать онлайн бесплатно",
          },
          { property: "og:site_name", content: "Bookstab" },
          {
            property: "og:description",
            content: this.metaDescr,
          },
          { property: "og:type", content: "book" },
          { property: "og:url", content: window.location.href },
          { property: "og:image", content: this.book.image_big },
        ],
      };
    }
    return {};
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    disableAdultBlock() {
      localStorage.setItem("adultblock", false);
      this.adultBlock = false;
    },
    getDonatLink() {
      if (this.book.user.donat && this.book.user.donat != "null") {
        // window.open(this.book.user.donat, "_blank");
        window.location.href = this.book.user.donat;
      }
    },
    getBook() {
      var app = this;
      this.$store
        .dispatch("reader/getBook", {
          // book_id: app.bookId,
          book_slug: app.bookSlug,
          chapter_id: app.chapterId,
          order: app.order,
        })
        .then((response) => {
          if (response.data.success == 1) {
            //разбиваем главу на страницы
            // app.chapterSplit();
          }
        })
        .catch(() => {});
    },
    getBuyLink() {
      var app = this;
      app.buyLoading = true;
      this.$store
        .dispatch("getBookBuyLink", {
          book_id: app.bookId,
          namespace: "reader",
        })
        .then((response) => {
          app.buyLoading = false;
          if (response.data.linkPay) {
            window.open(response.data.linkPay, "_blank");
          }
          if (response.data.hasBook) {
            //Обновляем книгу
            app.getBook();
          }
        })
        .catch(() => {
          app.buyLoading = false;
        });
    },
    toggleFullScreen() {
      // this.$fullscreen.toggle(this.$refs["fullscreen"], {
      //   wrap: false
      // });

      this.$refs["fullscreen"].toggle();
    },
    goPage(page) {
      // console.log("Текущая страница:" + page);
      if (page <= this.pages) {
        this.page = page;
        window.scrollTo(0, 0);
        localStorage.setItem("book_page_" + this.bookSlug, page);
      }
    },
    goChapter(chapter) {
      if (chapter && chapter.id) {
        this.$router.push({
          name: "ReaderShowFull",
          params: { bookSlug: this.book.slug, id: chapter.id },
        });
      }
    },
    addLibrary() {
      if (this.book.in_library) {
        return;
      }
      if (!this.currentUser) {
        this.$root.showAuthMessage();
        return;
      }
      let app = this;
      app.libraryLoading = true;
      this.$store
        .dispatch("libraries/addLibrary", {
          tableId: app.book.id,
          tableType: "book",
        })
        .then(() => {
          app.libraryLoading = false;
          app.book.in_library = true;
          this.showInLibrary = false;
          // localStorage.setItem("lib_modal_" + this.bookSlug, true);
        })
        .catch(() => {
          app.libraryLoading = false;
        });
    },
    closeShowInLibrary() {
      this.showInLibrary = false;
      localStorage.setItem("lib_modal_" + this.bookSlug, true);
    },

    speak() {
      const message = new SpeechSynthesisUtterance();
      var synth =
        window.speechSynthesis ||
        window.mozspeechSynthesis ||
        window.webkitspeechSynthesis;
      message.lang = "ru-RU";
      message.text = this.currentPageContent;

      if (synth.speaking) {
        if (synth.paused) {
          synth.resume();
        } else {
          synth.pause();
        }
      } else {
        synth.speak(message);
      }
    },
    play() {
      this.played = !this.played;
      this.$refs.Player.play();
    },
  },
  mounted() {
    // this.bookId = this.$route.params.bookId ? this.$route.params.bookId : "";
    this.bookSlug = this.$route.params.bookSlug
      ? this.$route.params.bookSlug
      : "";
    this.chapterId = this.$route.params.id ? this.$route.params.id : "";
    let locBrightness = localStorage.getItem("brightness");
    // console.log('locBrightness',locBrightness);
    if (locBrightness) {
      this.brightness = locBrightness;
    }
    let locReaderfontSize = localStorage.getItem("readerfontSize");
    if (locReaderfontSize) {
      this.readerfontSize = locReaderfontSize;
    }
    let locPaddingSize = localStorage.getItem("paddingSize");
    if (locPaddingSize) {
      this.paddingSize = locPaddingSize;
    }
    let locRowHeight = localStorage.getItem("rowHeight");
    //console.log('locRowHeight', locRowHeight);
    if (locRowHeight) {
      this.rowHeight = locRowHeight;
    }

    if (!this.chapterId) {
      //Получаем главу если у нас нет
      let locChapterId = localStorage.getItem("book_chapter_" + this.bookSlug);
      if (locChapterId) {
        this.chapterId = locChapterId;
      }
    }
    this.order = this.$route.query.order ? this.$route.query.order : null;
    this.getBook();

    //Остановка спикера
    window.speechSynthesis.cancel();
  },
  watch: {
    brightness(newBrightness) {
      localStorage.setItem("brightness", newBrightness);
    },
    readerfontSize(newReaderfontSize) {
      localStorage.setItem("readerfontSize", newReaderfontSize);
    },
    paddingSize(newPaddingSize) {
      localStorage.setItem("paddingSize", newPaddingSize);
    },
    rowHeight(newRowHeight) {
      localStorage.setItem("rowHeight", newRowHeight);
    },
    page() {
      if (!this.page || this.page > this.pages || this.page < 1) {
        this.page = 1;
        localStorage.setItem("book_page_" + this.bookSlug, this.page);
      }

      //Показ окна добавить в библиотеку
      if (this.page >= 3 && this.book && !this.admin) {
        let close_show_in_library =
          localStorage.getItem("lib_modal_" + this.bookSlug) || false;

        if (!close_show_in_library && !this.book.in_library) {
          this.showInLibrary = true;
        }
      }
    },
    chapter() {
      if (this.chapter) {
        this.chapterId = this.chapter.id;
        let locChapterId = localStorage.getItem(
          "book_chapter_" + this.bookSlug
        );

        if (locChapterId == this.chapterId) {
          this.page = +localStorage.getItem("book_page_" + this.bookSlug) || 1;
        } else {
          this.page = 1;
          localStorage.setItem("book_page_" + this.bookSlug, this.page);
        }

        localStorage.setItem("book_chapter_" + this.bookSlug, this.chapterId);
      }
    },
    adult_block() {
      let block = false;
      if (this.adult_block) {
        // if(this.currentUser){
        //   block = this.adult_block;
        // }
        // else{

        block = localStorage.getItem("adultblock");
        if (block == undefined) {
          block = true;
        } else {
          block = JSON.parse(localStorage.getItem("adultblock")) === true;
        }
        // }
      }
      this.adultBlock = block;
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      loadPage: "loadPage",
      book: "reader/book",
      chapter: "reader/chapter",
      body: "reader/body",
      body_speech: "reader/body_speech",
      show_buy: "reader/show_buy",
      adult_block: "reader/adult_block",
      similar: "reader/similar",
    }),
    chapters() {
      if (this.book) {
        return this.book.chapters_show;
      }
      return [];
    },
    darkTheme() {
      return this.themes[this.brightness].dark;
    },
    colorTheme() {
      return this.themes[this.brightness].color;
    },
    textColorTheme() {
      return this.themes[this.brightness].textColor;
    },
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    readerWidthMax() {
      let wWidth = Math.floor(this.$vuetify.breakpoint.width / 25) * 25;
      if (wWidth > 600) {
        return (wWidth - 600) / 25;
        // return wWidth / 25;
      }
      return 0;
    },
    readerWidth() {
      if (this.paddingSize > 0 && this.paddingSize <= this.readerWidthMax) {
        return this.readerWidthMax * 25 - this.paddingSize * 25 + 600 + "px";
      }
      return "100%";
    },
    admin() {
      if (
        this.currentUser &&
        this.book &&
        this.currentUser.id == this.book.user_id
      ) {
        return true;
      }
      return false;
    },
    chapterByPages() {
      if (this.body) {
        return this.body.split("<!--nextpage-->");
      }
      return [];
    },
    pages() {
      if (this.chapterByPages) {
        return this.chapterByPages.length;
      }
      return 1;
    },
    currentPageContent() {
      if (this.chapterByPages.length > 0) {
        return this.chapterByPages[this.page - 1];
      }
      return "";
    },
    chapterPos() {
      if (this.chapter && this.book) {
        for (let i = 0; i < this.chapters.length; i++) {
          if (this.chapter.id == this.chapters[i].id) {
            return i + 1;
          }
        }
      }
      return 1;
    },
    bookId() {
      if (this.book) {
        return this.book.id;
      }
      return null;
    },
    showContent() {
      if (this.showAddComment || this.adultBlock || this.show_buy) {
        return false;
      }
      return true;
    },
    showAddComment() {
      if (
        this.book &&
        this.book.show_add_comment &&
        this.chapter &&
        this.chapter.order >= 5
      ) {
        return true;
      }

      return false;
    },
  },
  beforeDestroy() {
    // this.$vuetify.theme.dark = false;
  },
};
</script>
